/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.scss';
@import 'primeicons/primeicons.css';
@import '_theme.scss';

html {
  scroll-behavior: smooth;
  font-size: 80%;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  font-size: 16px;
  margin: 0;
}
